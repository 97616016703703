.p-inplace-display {
  padding: 0% !important;
}

.p-inplace {
  padding: 0%;
  width: 100%;
}

.p-inplace-content {
  width: 100% !important;
  display: inline-flex !important;
}
